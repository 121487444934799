'use client'

import { useTranslations } from 'next-intl'
import * as React from 'react'

import { Image } from 'component/ui/image'
import { CDN_URL } from 'constant'
import useLazyRef from 'hook/useLazyRef'
import DownloadAppButton from './downloadAppButton'
import Logo from './logo'

class HomeAudioControl {
  private audio: HTMLAudioElement | null = null
  private container: HTMLDivElement | null = null
  private isPrevMuted: boolean = true

  constructor() {
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  public attach(container: HTMLDivElement, audio: HTMLAudioElement) {
    this.audio = audio
    this.container = container

    this.container.addEventListener('click', this.handleClick)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  public detach() {
    this.container?.removeEventListener('click', this.handleClick)
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  }

  private handleClick(event: MouseEvent) {
    if (
      event.target instanceof HTMLButtonElement ||
      event.target instanceof HTMLAnchorElement
    ) {
      return
    }

    const audio = this.audio
    if (!audio) {
      return
    }

    audio.play()
    audio.volume = 0.5
    audio.muted = !audio.muted
  }

  private handleVisibilityChange(event: Event) {
    const audio = this.audio
    if (!audio) {
      return
    }

    if (document.visibilityState === 'hidden') {
      this.isPrevMuted = audio.muted
      audio.muted = true
    } else {
      audio.muted = this.isPrevMuted
    }
  }
}

const Main = () => {
  const t = useTranslations()
  const audioRef = React.useRef<React.ElementRef<'audio'>>(null)
  const audioControl = useLazyRef(() => new HomeAudioControl())

  const containerCallbackRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node && audioRef.current) {
        audioControl.current.attach(node, audioRef.current)
      } else {
        audioControl.current.detach()
      }
    },
    [audioControl]
  )

  return (
    <div
      ref={containerCallbackRef}
      className='relative min-h-[640px] flex-1 min768:h-auto'
    >
      <div className='absolute z-[-1] h-full w-full'>
        <Image
          src={`${CDN_URL}/platform/images/web_home_video_desktop.png`}
          alt="Playsee's home page"
          fill
          className='z-0 hidden object-cover min768:block'
        />
        <Image
          src={`${CDN_URL}/platform/images/web_home_video_mobile.png`}
          alt="Playsee's home page"
          fill
          className='z-0 hidden object-cover min768:hidden'
        />
        <video
          muted
          loop
          autoPlay
          playsInline
          preload='auto'
          className='absolute left-0 top-0 hidden h-full w-full object-cover min768:block'
        >
          <source
            src={`${CDN_URL}/platform/videos/web_home_video_desktop.mp4`}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <video
          muted
          loop
          autoPlay
          playsInline
          preload='auto'
          className='absolute left-0 top-0 h-full w-full object-cover min768:hidden'
        >
          <source
            src={`${CDN_URL}/platform/videos/web_home_video_mobile.mp4`}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <audio ref={audioRef} autoPlay loop muted>
          <source
            src={`${CDN_URL}/platform/audios/Cinematic%20Ambient%20Guitars.wav`}
            type='audio/wav'
          />
        </audio>
      </div>
      <div className='flex h-full items-start justify-center min768:items-center'>
        <Logo />
        <DownloadAppButton>{t('common.download_app')}</DownloadAppButton>
      </div>
    </div>
  )
}

export default Main
