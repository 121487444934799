'use client'

import {
  forwardRef,
  useState,
  type ImgHTMLAttributes,
  type ReactNode,
  type SyntheticEvent
} from 'react'
import cn from 'util/cn'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: ReactNode
  fill?: boolean
}

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ fallback, fill, className, onError, alt, ...props }, ref) => {
    const [isError, setIsError] = useState(false)

    const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
      if (onError) {
        onError(e)
      }
      setIsError(true)
    }

    if (isError && fallback) {
      return <>{fallback}</>
    }

    return (
      <img
        {...props}
        ref={ref}
        className={cn(
          fill && 'absolute left-0 top-0 h-full w-full object-cover',
          className
        )}
        alt={alt}
        onError={handleError}
      />
    )
  }
)
